<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-img style="margin-left: 25%" class="img-fluid  mb-1"
              :src="appLogoImage"
              alt="logo"
              width="120"
              height="100"
          />
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1"
          >
            Password Resetting
          </b-card-title>
          <b-card-text class="mb-2">

          </b-card-text>

          <!-- form -->
          <validation-observer ref="PasswordResetValidationByUser">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
             
              <b-form-group
                label="Enter Code"
                label-for="registered_code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                >
                  <b-form-input
                    id="registered_code"
                    v-model="passcode"
                    :state="errors.length > 0 ? false:null"
                    name="registered_code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Enter New Password"
                label-for="new_password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="new password"
                  rules="required"
                >
                  <b-form-input
                    id="new_password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    name="new_password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Retype Password"
                label-for="retype_password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="retype password"
                  rules="required"
                >
                  <b-form-input
                    id="retype_password"
                    v-model="retypepassword"
                    :state="errors.length > 0 ? false:null"
                    name="retype_password"
                    placeholder="Retype password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="ResetPassword"
              >
                Reset Password
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol,  BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import apiCall from "@/libs/axios";
import {getHomeRouteForLoggedInUser, isUserLoggedIn} from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props:{
    phoneNumber:{
      required:true,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      passcode: '',
      password:'',
      retypepassword:'',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogoImage: require('@/assets/images/logo/logo.png'),
      required,
      email
    }
  },
  created() {
    if (isUserLoggedIn()) this.$router.replace(getHomeRouteForLoggedInUser(this.$store.getters.user.role));
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ResetPassword() {
      if(this.password != this.retypepassword)
      {
        alert("Password and retype password is not matched");
        return;
      }
      else
      {
        this.$refs.PasswordResetValidationByUser.validate().then(success => {
        if (success) {
          let formData = new FormData();
         
          formData.append('registered_phone_number', this.$route.params.phone);
          formData.append('passcode', this.passcode);
          formData.append('password', this.password);
          formData.append('retypepassword', this.retypepassword);
          
          apiCall.post('/reset/password/by/self', formData)
              .then((response) => {

                if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.$router.push({ name: 'login' })
                }
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'warning',
                    text: 'Invalid request',
                  },
                })
              })
          }
       })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
